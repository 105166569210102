import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import Loadable from "components/Loadable";
import { MainLayout } from "layouts/main";

const HomePage = Loadable(lazy(() => import("./pages/Home")));

const NotFoundPage = Loadable(lazy(() => import("./pages/NotFound")));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <HomePage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default router;
