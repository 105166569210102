import { StyledThemeProvider } from "definitions/styled-components";
import { RouterProvider } from "react-router-dom";

import router from "router";
import GlobalStyle from "styles/globalStyles";
import "./i18n";

import "./App.css";

function App(): JSX.Element {
  return (
    <StyledThemeProvider>
      <GlobalStyle />
      <RouterProvider router={router} />
    </StyledThemeProvider>
  );
}

export default App;
