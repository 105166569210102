import React from "react";
import { ThemeProvider } from "styled-components/macro";
import { ConfigProvider } from "antd";
import { darken } from "polished";

import { dark } from "./dark";
import { light } from "./light";

export const ThemeContext = React.createContext({
  theme: "light",
  toggle: () => {},
});

export const useTheme = () => {
  const { theme, toggle } = React.useContext(ThemeContext);

  return { theme: theme === "light" ? light : dark, toggle, themeName: theme };
};

interface StyledThemeProviderProps {
  children: React.ReactNode;
}

export const StyledThemeProvider: React.FC<StyledThemeProviderProps> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState("light");

  const toggle = React.useCallback(() => {
    setTheme((theme) => (theme === "light" ? "dark" : "light"));
  }, []);

  const values = React.useMemo(
    () => ({
      theme,
      toggle,
    }),
    [toggle, theme]
  );

  return (
    <ThemeContext.Provider value={values}>
      <ThemeProvider theme={theme === "light" ? light : dark}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#f97316",
              colorLink: "#f97316",
              colorLinkHover: darken(0.15, "#f97316"),
              fontFamily:
                'Inter var,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            },
            // components: {
            //   Layout: {
            //     colorBgHeader: "#6F130F",
            //   },
            // },
          }}
        >
          {children}
        </ConfigProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export * from "./common";
export * from "./dark";
export * from "./light";
