import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const FallbackLoading: React.FC = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin spinning indicator={<LoadingOutlined />} size="large" />
    </div>
  );
};

export default FallbackLoading;
