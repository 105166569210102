import { Suspense } from "react";

import FallbackLoading from "components/FallbackLoading";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<FallbackLoading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;
